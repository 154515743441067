<template>
  <main>
    <div>
      <article>
        <section>
          <div class="careersccbg1">
            <div
              class="container"
              style="height: 100%;"
            >
              <div class="careersccbg1_60">
                <h1
                  class="fs3"
                  style="color: rgb(178, 236, 178);"
                >Access Uganda Tours</h1>
                <p class="fsp">
                  We welcome you to the true African wildlife and intriging cultural experiences.
                  We let you step into the impenetrable ansd scenic African Jungle, embracing the quiet moments, watching the
                  marveloues fauna without overlooking the mighty mountain gorillas whilst catching the breathtaking scenaries.
                </p>
              </div>
            </div>
          </div>
        </section>
      </article>

      <article>
        <section>
          <div class="career_statement">
            <div class="container">
              <div class="width80">
                <h2 class="fs3">Expertise.</h2>
                <p class="fsp">
                  Our team of highly skilled travel specialists and consultants are based
                  throughout the East African region where they have been living and working
                  for many years. Together they possess over 60 years of safari operating
                  experience across the East African region. We only work with the best guides
                  in the country who are not only professional local experts but also add that
                  personal touch to your journey.
                </p>
              </div>
            </div>
          </div>
        </section>
      </article>

      <article>
        <section>
          <div
            class="careersart3"
            style="background-color: #f5e2a3; padding-top: 50px;"
          >
            <div class="container">
              <div class="art3_grid">
                <div @click="$router.push({path: '/about-us'})">
                  <img
                    src="../assets/images/accesstp.jpg"
                    width="100%"
                  >
                  <div>
                    <div
                      class="text-center text-white"
                      style="width: 100%; padding:20px;"
                    >
                      <span class="fs4"><strong>Transport</strong></span><br />
                      <span>We use only the most dependable vehicles for all our tours. We aim to match the type and size of car with your group size and budget.</span>
                    </div>
                  </div>
                </div>
                <div @click="$router.push({path: '/about-us'})">
                  <img
                    src="../assets/images/trekkers.jpg"
                    width="100%"
                  >
                  <div>
                    <div
                      class="text-center text-white"
                      style="width: 100%; padding:20px;"
                    >
                      <span class="fs4"><strong>Accomodation</strong></span><br />
                      <span>Access Uganda Tours stands out in its accommodation concept: We visit all accommodations ourselves to be able to select the best ones for you and to give you advice from personal experience! </span>
                    </div>
                  </div>
                </div>
                <div @click="$router.push({path: '/about-us'})">
                  <img
                    src="../assets/images/stock.jpg"
                    width="100%"
                  >
                  <div>
                    <div
                      class="text-center text-white"
                      style="width: 100%; padding:20px;"
                    >
                      <span class="fs4"><strong>Tailor made tours</strong></span><br />
                      <span>Creating your own trip is the best way of getting everything out of your holiday. The Access Uganda Tours staff is well known for being innovative and creative. Use our specialism to create your own dream holiday!</span>
                    </div>
                  </div>
                </div>
                <div>
                  <img
                    src="../assets/images/tailor_made.jpg"
                    width="100%"
                  >
                  <div>
                    <div
                      class="text-center text-white"
                      style="width: 100%; padding:20px;"
                    >
                      <span class="fs4"><strong>Sustainable tourism</strong></span><br />
                      <span>Access Uganda Tours takes its responsibility to limit the negative influences on social/economic aspects, and environmental issues as much as possible.</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <section>
              <div class="container text-center">
                <div class="art3sec121">
                  <h3 class="fs5">
                    Whilst it is our endeavor to offer you extraordinary and authentic African
                    experiences, we are also committed to sustainability and to do our part in
                    protecting the natural environment, the African wildlife and the valuable
                    cultural heritage.
                  </h3>
                  <!-- <p class="fs4"><a href="#">Appy here > </a></p> -->
                </div>
              </div>
            </section>
          </div>
        </section>
      </article>
    </div>

    <div>
      <article>
        <section>
          <div
            class="container"
            style="padding-top: 100px; padding-bottom: 50px; background-color: #eeeeee;"
          >
            <h3><strong>Access Uganda Tours TRANSPORT OPTIONS.</strong></h3><br />
            <div class="why_lugave">
              <div style="display: flex; align-items: center; justify-content: center;">
                <img
                  alt="Access Uganda Tours Transport"
                  src="../assets/images/safari_vehicle.jpeg"
                  style="height: 300px; width:100%; object-fit: cover;"
                >
              </div>
              <div style="color:black;">
                <p style="color:black;">
                  We use only the most dependable vehicles for all our tours. We aim to match the type and size of car with your group size and budget.
                </p>
                <p style="color:black;">
                  Access Uganda Tours owns minibuses with 7 window-seats and Landcruisers with 3, 5 and 7 window-seats. All cars are 4WD and have a pop-up or sunroof for an excellent safari view.
                </p>
                <p style="color:black;">
                  In the Access Uganda Tours' Kampala workshop the cars are serviced after each trip. Cars with air-conditioning can be booked on request.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div
            class="container"
            style="padding-top: 50px; padding-bottom: 50px;"
          >
            <div class="why_lugave2">
              <div style="color:black;">
                <h4><strong>Tour vehicles</strong></h4>
                <p style="color:black;">
                  We use only the most dependable vehicles for all our tours. We aim to match the type and size of car with your group size and budget.
                </p>
                <p style="color:black;">
                  Access Uganda Tours Landcruisers with 3, 5 and 7 window-seats. All cars are 4WD and have a pop-up or sunroof for an excellent safari view.
                </p>
                <p style="color:black;">
                  In the Access Uganda Tours' Kampala workshop the cars are serviced after each trip. Cars with air-conditioning can be booked on request.
                </p>
              </div>
              <div style="display: flex; align-items: center; justify-content: center;">
                <img
                  alt="Access Uganda Tours Transport"
                  src="../assets/images/extended2.jpg"
                  style="height: 100%; width:100%; object-fit: cover;"
                >
              </div>
            </div>
          </div>
        </section>

        <section>
          <div
            class="container"
            style="padding-top: 50px; padding-bottom: 50px; background-color: #eeeeee;"
          >
            <div class="why_lugave">
              <div style="display: flex; align-items: center; justify-content: center;">
                <img
                  alt="Access Uganda Tours Transport"
                  src="../assets/images/extended.jpg"
                  style="height: 100%; width:100%; object-fit: cover;"
                >
              </div>
              <div style="color:black; background-color: #eeeeee;">
                <h4><strong>Extended Toyota Landcruiser.</strong></h4><br />
                <p style="color:black;">
                  The extended Toyota Landcruiser is for some the ultimate safari vehicle in Africa. It's 4WD, spacious, strong, and sits high above the road.
                </p>
                <p style="color:black;">
                  There is air-conditioning in the car, an open roof for an excellent safari view, and even a fridge on board! This is the standard option on all mainland Tanzania tours and an upgrade option for Kenya, Rwanda and Uganda tours.
                </p>
              </div>
            </div>
          </div>
        </section>
      </article>
    </div>

    <div>
      <article>
        <section>
          <div
            class="container"
            style="padding-top: 100px; padding-bottom: 50px; background-color: #eeeeee;"
          >
            <h3><strong>ACCOMMODATION CHOICES.</strong></h3><br />
            <div class="why_lugave">
              <div style="display: flex; align-items: center; justify-content: center;">
                <img
                  alt="Access Uganda Tours Transport"
                  src="../assets/images/trekkers.jpg"
                  style="height: 100%; width:100%; object-fit: cover;"
                >
              </div>
              <div style="color:black;">
                <p style="color:black;">
                  Access Uganda Tours stands out for over 10 years in its accommodation concept: We visit all accommodations ourselves to be able to select the best ones for you and to give you advice from personal experience!
                </p>
                <p style="color:black;">
                  Our selection criteria are focused on the view, service, location,
                  style and price of each lodge. We also select accommodation based on the
                  feedback from previous clients.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div
            class="container"
            style="padding-top: 50px; padding-bottom: 50px;"
          >
            <h4>How We Rate Accomodation</h4>
            <p style="color:black;">
              “Budget” - Accommodation such as simple guesthouses or dormitory rooms are available
              in Uganda. Service and amenity standards won't be high and you are likely to share
              facilities with other guests, but choosing this standard can make a big difference
              in the price of the tour. Alternatively, you can choose to camp.
              Some campsites have wonderful locations and Access Uganda Tours can provide you with tents
              and mattresses.
            </p>
            <p style="color:black;">
              “Mid-range” - Access Uganda Tours originally focused mainly on the mid-range market and
              therefore has a special ability to choose the best accommodation of this standard.
              This is your best option for comfortable, self-contained ('en suite') accommodation
              set in stunning locations that don't cost a fortune.
            </p>
            <p style="color:black;">
              “Semi-luxe” - Accommodation falling in this category is more comfortable than the
              mid-range. The locations are similar, but the details are further refined.
              These lodges often have a swimming pool, as well.
            </p>
            <p style="color:black;">
              “Luxury” - The luxurious accommodation is fully equipped, comfortable and usually
              more spacious than the mid-range options. They often have facilities like swimming
              pools, masseurs and sometimes even personal butlers.
            </p>
          </div>
        </section>
      </article>
    </div>

    <div>
      <article>
        <section>
          <div
            class="container"
            style="padding-top: 100px; padding-bottom: 50px; background-color: #eeeeee;"
          >
            <h3><strong>TAILOR MADE TOURS.</strong></h3><br />
            <div class="why_lugave">
              <div style="display: flex; align-items: center; justify-content: center;">
                <img
                  alt="Access Uganda Tours Transport"
                  src="../assets/images/equator.jpg"
                  style="height: 300px; width:100%; object-fit: cover;"
                >
              </div>
              <div style="color:black;">
                <h4><strong>Create your own dreams!</strong></h4>
                <p style="color:black;">
                  Creating your own trip is the best way of getting everything out of your
                  holiday. The Access Uganda Tours staff is well known for being innovative and
                  creative. Use our specialism to create your own dream holiday!
                </p>
                <p style="color:black;">
                  Please fill out the form below and you'll receive our offer and itinerary
                  for a quality trip. You may expect a response within 24 hours on workdays
                  (Monday-Friday).
                </p>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div
            class="container text-center"
            style="padding-top: 50px; padding-bottom: 50px;"
          >
            <el-button
              style="background-color: #D96B12; color: white;"
              @click="$router.push({ path: '/tailor-made-safaris' })"
            >Create your own dream Safaris <i class="el-icon-d-arrow-right"></i></el-button>

          </div>
        </section>
      </article>

    </div>
  </main>
</template>

<script>
export default {
  data() {
    return {
      isSubmitting: false,
      countries_list: ["Uganda", "Kenya", "Tanzania", "Rwanda"],
      accommodation_types: ["Budget", "Middle Class", "Luxury"],
      budgets: [
        { name: "$0.00 - $1000", value: "$0.00 - $1000" },
        { name: "$1000 - $2000", value: "$1000 - $2000" },
        { name: "$2000 - $3000", value: "$2000 - $3000" },
        { name: "$3000 - $4000", value: "$3000 - $4000" },
        { name: "Not Important", value: "not_important" },
      ],
      interests: [
        "Birding",
        "Wildlife",
        "Primates(Gorillas & Chimpanzees)",
        "Mountaineering",
        "Culture",
      ],
      enquiryFormData: {
        name: "",
        nationality: "",
        email: "",
        country: "",
        travel_period: "",
        number_of_days: "",
        number_of_persons: "",
        accommodation: "",
        budget_indication: "",
        interests: [],
        comments: "",
      },

      rules: {
        name: [
          {
            required: true,
            message: "Full Name is required",
            trigger: "blur",
          },
        ],
        nationality: [
          {
            required: true,
            message: "Nationality is required",
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            message: "Email is required",
            trigger: "blur",
          },
        ],
        country: [
          {
            required: true,
            message: "Country of destination is required",
            trigger: "change",
          },
        ],
        travel_period: [
          {
            required: true,
            message: "Travel Period is required",
            trigger: "change",
          },
        ],
        number_of_days: [
          {
            required: true,
            message: "Number of days are required",
            trigger: "blur",
          },
        ],
        number_of_persons: [
          {
            required: true,
            message: "Number of Persons are required",
            trigger: "blur",
          },
        ],
        accommodation: [
          {
            required: true,
            message: "accommodation is required",
            trigger: "blur",
          },
        ],
        budget_indication: [
          {
            required: true,
            message: "budget_indication is required",
            trigger: "change",
          },
        ],
        interests: [
          {
            required: true,
            message: "interests are required",
            trigger: "change",
          },
        ],
        comments: [
          {
            required: true,
            message: "Comment is required",
            trigger: "blur",
          },
        ],
      },
    };
  },

  methods: {
    async submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          try {
            this.isSubmitting = true;
            let request = await this.$http.post(
              `api/enquiry/add`,
              this.enquiryFormData
            );
            if (
              request.data.success &&
              request.data.message == "ENQUIRY_ADDED_SUCCESSFULLY"
            ) {
              this.$notify({
                title: "Success",
                message: "Tailor Made Tour Submitted Successfully",
                type: "success",
              });
            } else throw "UNEXPECTED_RESPONSE";
          } catch (error) {
            if (error.message == "NetworkError") {
              this.isSubmitting = false;
              return this.$notify({
                title: "Connection failed",
                message: "A network error occurred please try again",
                type: "warning",
              });
            }
            this.isSubmitting = false;
            this.$notify({
              title: "Submission Failed",
              message: "Unable to Submit Tailor Made Safari, Please try again",
              type: "error",
            });
          } finally {
            this.isSubmitting = false;
          }
        } else {
          this.$notify({
            title: "Submission Error",
            message: "Unable to Submit Tailor Made Safari Data.",
            type: "error",
          });
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>

<style scoped>
.width80 {
  width: 80%;
}
p {
  color: black;
}

label {
  margin-top: 20px;
  color: grey;
}

.careersccbg1 {
  background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.9)),
    url("../assets/images/hassan.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
  height: 500px;
}

.careersccbg1 > div {
  display: flex;
  align-items: flex-end;
  padding-bottom: 60px;
  color: white;
}
.careersccbg1 > div h1,
p {
  color: #f5f2e6;
}
.careersccbg1_60 {
  width: 60%;
}

.career_statement {
  padding: 100px 0px;
  background-color: #221b06;
  color: whitesmoke;
}

.careersart3 {
  background-color: #221b06;
}

.art3_grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}

.art3_grid > div {
  height: 400px;
  background-color: #eeeeee;
  position: relative;
  cursor: pointer;
}

.art3_grid > div > div {
  position: absolute;
  width: 100%;
  top: 0px;
  bottom: 0px;
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8));
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.art3_grid > div img {
  height: 400px;
  object-fit: cover;
}

.art3sec121 {
  padding: 100px 100px;
}

.why_lugave {
  display: grid;
  grid-template-columns: 1fr 2fr;
  color: black;
}
.why_lugave > div:first-child {
  background-color: #5c4b13;
}
.why_lugave > div:nth-child(2) {
  padding: 20px 20px 20px 50px;
  background-color: white;
  color: black;
}

.why_lugave2 {
  display: grid;
  grid-template-columns: 2fr 1fr;
  color: black;
}
.why_lugave2 > div:nth-child(2) {
  background-color: #5c4b13;
}
.why_lugave2 > div:first-child {
  padding: 20px 20px 20px 50px;
  background-color: white;
  color: black;
}

/*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
@media (min-width: 481px) and (max-width: 767px) {
  .width80 {
    width: 100%;
  }
  .careersccbg1 {
    height: 400px;
  }
  .careersccbg1 > div {
    display: flex;
    align-items: flex-end;
    padding-bottom: 20px;
    color: white;
  }
  .careersccbg1_60 {
    width: 100%;
    text-align: center;
  }
  .career_statement {
    padding: 50px 0px;
    background-color: #221b06;
    color: whitesmoke;
    text-align: center;
  }
  .art3_grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }
  .art3sec121 {
    padding: 50px 20px;
  }

  .why_lugave {
    display: grid;
    grid-template-columns: 1fr;
  }
  .why_lugave > div:first-child {
    background-color: #5c4b13;
  }
  .why_lugave > div:first-child {
    padding: 20px 20px 20px 50px;
    background-color: white;
  }
}

/*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
@media (min-width: 320px) and (max-width: 480px) {
  .width80 {
    width: 100%;
  }
  .careersccbg1 {
    height: 400px;
  }
  .careersccbg1 > div {
    display: flex;
    align-items: flex-end;
    padding-bottom: 20px;
    color: white;
  }
  .careersccbg1_60 {
    width: 100%;
    text-align: center;
  }
  .career_statement {
    padding: 50px 0px;
    background-color: #221b06;
    color: whitesmoke;
    text-align: center;
  }
  .art3_grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }
  .art3sec121 {
    padding: 50px 20px;
  }

  .why_lugave {
    display: grid;
    grid-template-columns: 1fr;
  }
  .why_lugave > div:nth-child(2) {
    padding: 10px;
    background-color: white;
  }

  .why_lugave2 {
    display: grid;
    grid-template-columns: 1fr;
  }
  .why_lugave2 > div:first-child {
    padding: 10px;
    background-color: white;
  }
}
</style>