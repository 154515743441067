var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_c('section',[_c('article',{staticClass:"about_us_article_five pt-5 pb-5"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"about_us_article_five_grid"},[_vm._m(6),_c('div',[_c('div',[_c('h2',[_vm._v("Tailor Made Tours")]),_c('p',[_vm._v("Create your own dreams!")]),_c('p',{staticStyle:{"color":"black"}},[_vm._v(" Creating your own trip is the best way of getting everything out of your holiday. The Access Uganda Tours staff is well known for being innovative and creative. Use our specialism to create your own dream holiday! ")]),_c('p',{staticStyle:{"color":"black"}},[_vm._v(" Please fill out the form below and you'll receive our offer and itinerary for a quality trip. You may expect a response within 24 hours on workdays (Monday-Friday). ")]),_c('el-button',{staticStyle:{"background-color":"#D96B12","color":"white"},on:{"click":function($event){return _vm.$router.push({ path: '/tailor-made-safaris' })}}},[_vm._v("Create your own dream Safaris "),_c('i',{staticClass:"el-icon-d-arrow-right"})])],1)])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('section',[_c('article',{staticClass:"about_us_article_one"},[_c('img',{attrs:{"src":require("../assets/images/IMG_0046.jpg")}}),_c('div',{staticClass:"about_us_article_one_description"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-9"},[_c('h1',[_vm._v("It is our mission to enrich your life with unforgettable safari travel experiences")]),_c('p',{staticClass:"mt-4"},[_vm._v("We love to travel and we want to share our excitement with you!")])])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',[_c('article',{staticClass:"about_us_article_two pt-5 pb-5"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('p',{staticClass:"italic-text"},[_vm._v(" Experience all the Pearl of Africa has to offer as you tour through our cities, National Parks, and Wildlife Reserves. ")])]),_c('div',{staticClass:"col-md-8"},[_c('h2',[_vm._v("Access Uganda Safaris")]),_c('p',[_vm._v(" All associates have been involved in the tourism business since 1994 working as Professional Tour guides. Access Uganda Tours ensures you will always be in safe hands as we guide our trips ourselves. This experience ensures our clients of an excellent and memorable trip. ")]),_c('p',[_vm._v(" We are active members of UGANDA SAFARI GUIDES ASSOCIATION and executive members of the UGANDA BIRD GUIDES CLUB. ")]),_c('p',[_vm._v(" We have handled all types of clients from all parts of the world including: Adventure seekers, bird watchers and Twitchers, Film crews, Humanitarians, and even Honeymooners! ")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',[_c('article',{staticClass:"about_us_article_three pt-5 pb-5"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"about_us_article_three_grid"},[_c('div',[_c('div',[_c('h2',[_vm._v("Unforgettable and enriching travel, the \"Access Uganda\" way")]),_c('p',[_vm._v(" We believe that travel experiences are unforgettable because they open us to untried experiences, new skills and different perspectives. Through travel and learning in an enjoyable way, our lives are enriched, we meet new people, and we expand our potential. ")])])]),_c('div',[_c('img',{staticClass:"w-100",attrs:{"src":require("../assets/images/stock.jpg")}})])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',[_c('article',{staticClass:"about_us_article_four pt-5 pb-5"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"about_us_article_four_grid"},[_c('div',[_c('div',{staticClass:"text-center w-100 h-100"},[_c('h2',[_vm._v("+500")]),_c('h3',[_vm._v("Tourists per month")]),_c('p',[_vm._v("We have handled all types of clients from all parts of the world including: Adventure seekers, bird watchers and Twitchers, Film crews, Humanitarians, and Honeymooners! ")])])]),_c('div',[_c('div',{staticClass:"text-center w-100 h-100"},[_c('h2',[_vm._v("+4")]),_c('h3',[_vm._v(" Countries")]),_c('p',[_vm._v("We are the East African Tour specialists, have a unique safari in , Uganda, Kenya, Tanzania, Rwanda ")])])]),_c('div',[_c('div',{staticClass:"text-center w-100 h-100"},[_c('h2',[_vm._v("+100")]),_c('h3',[_vm._v("Travel packages")]),_c('p',[_vm._v("Our trips are offered on a full package basis that includes accommodation, park entries, vehicle, and guide fees.")])])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',[_c('article',{staticClass:"about_us_article_five pt-5 pb-5"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"about_us_article_five_grid"},[_c('div',[_c('img',{staticClass:"w-100",attrs:{"src":require("../assets/images/hassan.jpg")}})]),_c('div',[_c('div',[_c('h2',[_vm._v("Who we are")]),_c('p',[_vm._v(" All associates have been involved in the tourism business since 1994 working as Professional Tour guides. Access Uganda Tours ensures you will always be in safe hands as we guide our trips ourselves. This experience ensures our clients of an excellent and memorable trip. We are active members of UGANDA SAFARI GUIDES ASSOCIATION and executive members of the UGANDA BIRD GUIDES CLUB. ")])])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',[_c('article',{staticClass:"about_us_article_three pt-5 pb-5"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"about_us_article_three_grid"},[_c('div',[_c('div',[_c('h2',[_vm._v("What drives us")]),_c('p',[_vm._v(" We believe that travel can be a positive influence in personal and community growth. We consistently provide high-quality content, a superior user experience, and exceptional customer care. "),_c('br'),_vm._v(" We offer you the security, efficiency, and peace-of-mind needed when booking unforgettable holidays. Each travel experience is unique, and each individual involved in creating that experience is unique. We embrace this diversity. ")])])]),_c('div',[_c('img',{staticClass:"w-100",attrs:{"src":require("../assets/images/extended.jpg")}})])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{staticClass:"w-100",attrs:{"src":require("../assets/images/equator.jpg")}})])
}]

export { render, staticRenderFns }